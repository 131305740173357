import React from "react"
import styled from "styled-components"

import GlobalFonts from "../font/fonts"

import topbackground from "../assets/TopPolygon1.svg"
import topbackground2 from "../assets/TopPolygon2.svg"
import topbackground3 from "../images/topbackground2.png"

import ResponsiveAppBar from "../components/ResponsiveAppBar"
import FooterContainer from "../components/FooterContainer"

import Face from "../images/jimmy2.jpg"
import Face0 from "../images/bryan.png"

const OurMission = () => {

  const handleOpenBlog = () => {
    window.location.href = '/blog'
  }

  const handleOpenVideos = () => {
   window.location.href = '/videos' 
  }

  const handleOpenWebinar = () =>{
    window.location.href = '/webinar'
  }
  const handleOpenCaseStudies  = () =>{
    window.location.href = '/our-mission'
  }

  return (
    <>
    <GlobalFonts />
    <Background>
      <TopBackground src={topbackground3} />
      </Background>
  	<ResponsiveAppBar />
    <Top>    
        <HowItWorks>OUR MISSION</HowItWorks>
        <HowItWorks2>OUR MISSION</HowItWorks2>
        <H1Text>We believe owning real estate is essential for long term, generational wealth creation.  Our mission is to lower the barrier of entry to enable everyone to own real estate anywhere</H1Text>
      </Top>
      <Body>
        <Hero3>
          <UserImg src={Face0} />
          <Hero4>
              <HowSubText1>Bryan Marks, Founder</HowSubText1>
              <HowSubText2>Bryan Marks and Jimmy Woodard are the cofounders of Cloud Castles, a real estate investing app that lets you buy snackable shares of vacation homes that you can stay in and earn money from. They’ve known each other since 2010 when they met as students at UC Berkeley.</HowSubText2>
          </Hero4>
        </Hero3>

        <Hero3>
              
          <UserImg src={Face} />
          <Hero4> 
              <HowSubText1>Jimmy Woodard, Co-Founder</HowSubText1>
              <HowSubText2> Since then they’ve built over a decade of experience in the tech industry, and plan on using that knowledge to make real estate easier and more accessible for everyone. Because let’s be honest, real estate investing is in serious need of an upgrade.</HowSubText2>
          </Hero4>
          </Hero3>

        </Body>
        <FooterContainer />
    </>
  )
}

export default OurMission

const Background = styled.div`
  width:100%;
  overflow-x: hidden;
  overflow: hidden;
`


const Body = styled.div`
  width:100%;
  height:100%;
	  // height:200px;  
    
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

	@media (max-width: 1332px) {
    // margin: 50px 0px 100px 50px;

		// grid-template-columns: repeat(2, 1fr);
	}
	@media (max-width: 767px) {
    // margin: 50px 0px 100px 50px;
		// grid-template-columns: repeat(1, 1fr);
	}

    @media (max-width: 300px) {
    // margin: 50px 0px 50px;
    
    // grid-template-columns: repeat(1, 1fr);
  }
`

const Top = styled.div`
  zPosition:-100;
  height:500px; 
  // width:80%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-text:center;
  flex-direction: column;

	@media (max-width: 1332px) {
		
	}
	@media (max-width: 769px) {
		
	}
  @media (max-width: 425) {
    height:300px; 
  }
`

const Hero3 = styled.div`
  position:relative;
  margin-top:  30px;
  margin-bottom:  30px;
  // margin-left:  auto;
 
  border-radius:33px;
  
  display: flex;
  // width:100%;
  padding:25px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items:center;
   background-color:white;
   overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  &:hover {
    // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
      top: -15px;
      
      background: white;
      box-shadow: 0px ;

  }

  margin-left:  230px;
  margin-right:  230px;
  @media (max-width: 1132px) {
    flex-direction: column;
    margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
      margin-top:70px;
      margin-left:  80px;
    margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    margin-top:100px;
    margin-top:50px;
    width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

`
const UserImg = styled.img`
  // margin: 30px;
  height: 20%;
  width: 20%;
  border-radius: 100%;
    animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  background: url(Image.png);
  @media (max-width: 1000px) {
  height:30%;
  width: 30%;
    
  }
  @media (max-width: 767px) {
    
  }

  @media (max-width: 300px) {

  }
`


const Hero4 = styled.div`
  // margin-top:  auto;
  // margin-bottom:  auto;
  margin:  15px;
  align-text:  left;
  width:80%;
  
  // margin-right:  30px;
  // display: flex;
  // // width:100%;
  // flex-direction: column;
  // // justify-content: center;
  // align-items:center;
`

const HowItWorks = styled.h2`
  position: relative;
  // width: 616px;
  height: 99px;
    left: auto;
  right: auto;
  top: 100.33px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 65.7269px;
  line-height: 99px;
  /* identical to box height */

  letter-spacing: 1.56101px;
  text-transform: uppercase;

  color: #000000;

  opacity: 0.04;

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

    @media only screen and (max-width: 425px) and (min-width: 100px) {
    // left: 60.12px;
    font-size: 46.9714px;
    width:100%;
    padding-top:100px;
  }
`

const HowItWorks2 = styled.h2`
  position: relative;
  // width: 358px;
  height: 60px;
  left: auto;
  right: auto;
  top: 50.63px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36.9714px;
  line-height: 55px;
  letter-spacing: 1.56101px;
  text-transform: uppercase;

  color: #000000;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // left: 60.12px;
    top: 0.63px;
    padding-top:140px;
  }
`


const Icon1 = styled.img`
  position: absolute;
   object-fit: cover;
  width: 100%;
  height: 300px;
  border-radius: 33px 33px 0px 0px
  
  // top: 0.39px;


`
const HowText1 = styled.h2`
  position: relative;
  
  height: 34px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top:310px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 24.6476px;
  line-height: 33px;

  /* or 134% */
  color: #1d293f;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const HowSubText1 = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 20.8802px;
  line-height: 100%;
  /* or 43px */

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  font-feature-settings: "liga" off;

  // margin: 40px;
  margin: 20px;
  text-align:left;
  // margin-top: 80px;
  // padding: auto;

  color: black;

`

const HowSubText2 = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 15.8802px;
  line-height: 120%;
  /* or 43px */

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  font-feature-settings: "liga" off;

  // margin: 40px;
  margin-left: 20px;
  text-align:left;
  // margin-top: 80px;
  // padding: auto;

  color: black;
  @media (max-width: 900px) {
   font-size: 15.8802px;
  }
`

const Icon2 = styled.img`
  position: absolute;
  width: 94.29px;
  height: 94.29px;
  left: 53.31px;
  top: 21.39px;
    @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const TopBackground = styled.img`

  position: absolute;
  // left: -34.63%;
  // right: -10.75%;
  top: -10.3%;
  width:100%;
  // bottom: 78.14%;
  z-index:-100;
  overflow-x: hidden;
  background: #8bbef3;
  border-radius: 333px;
  
  // transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
`
const H1Text = styled.h1`
  position:relative;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 20.8802px;
  line-height: 119%;
  /* or 43px */

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  font-feature-settings: "liga" off;

  margin-top: 70px;
  margin-bottom: 70px;
  margin-right:auto;
  margin-left:auto;
   text-align:center;
  // margin-left: 80px;
  // margin-top: 80px;
  padding: auto;

  width:40%;
  color: black;
  box-shadow: 0px 3.58802px 22.4251px rgba(1,1, 1, 0.9);
  border-radius: 33px;
  padding:30px;
  background: white;

  @media only screen and (max-width: 1299px) and (min-width: 601px) {
     margin-top: 100px;
  margin-bottom: 100px;
  width:90%;
  }
  @media only screen and (max-width: 600px) and (min-width: 100px) {
       // margin-top: 100px;
    margin-bottom: 130px;
    width:90%;
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    margin-top:100px;
    width:90%;
  }
`
